.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.error-message {
    color: red;
}

#raw-config {
    width: 100%;
    height: 30rem;
    font-family: "Courier New", Courier, monospace;
}

.Content {
    padding-bottom: 5rem;
}

.plot {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.dataset-modal {
    width: 90%;
    max-width: none;
}
